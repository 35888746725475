.header-img {
  position: relative;
}
.header-img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--black-alpha-40);
}
.aside-header {
  position: relative;
}
.header-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header-info i,
.header-info span {
  display: block;
  color: var(--WHITE);
}
.info-icon i {
  font-size: 2rem;
  margin: auto;
  padding-left: 5px;
}
.info-icon {
  background-color: var(--main-color);
  border-radius: 50%;
  height: 65px;
  width: 65px;
  display: flex;
}

.aside-features .feature-icon {
  width: 2rem;
  text-align: center;
}
