.instructor .circular-img {
  position: relative;
  width: 15rem;
  height: 15rem;
  margin: auto;
}
@media (min-width: 992px) {
  .instructor .circular-img {
    width: 17rem;
    height: 17rem;
  }
}
@media (min-width: 1200px) {
  .instructor .circular-img {
    width: 19rem;
    height: 19rem;
  }
}
.instructor .circular-img::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: var(--main-color);
  opacity: 0.3;
}
.instructor .circular-img img {
  position: absolute;
  left: 0;
  bottom: -0.5%;
  border-radius: 0 0 50% 50%;
  height: 112%;
}
