/*! color 1 */
.color-1 {
  --hue-value-1: 0;
  --hue-value-2: 230;
  --main-color: hsl(var(--hue-value-1), 74%, 61%);
  --button-hover-color: hsl(var(--hue-value-1), 57%, 53%);
  --body-bg-color: hsl(var(--hue-value-1), 63%, 97%);
  --border-color-2: hsl(var(--hue-value-1), 40%, 93%);
  --shadow: 0 0 10px hsl(var(--hue-value-1), 40%, 90%);
  --body-gradient-bg-color-1: hsla(var(--hue-value-1), 100%, 50%, 0.2);
  --body-gradient-bg-color-2: hsla(var(--hue-value-2), 100%, 50%, 0.2);
  --decoration-bg-color-1: hsl(var(--hue-value-1), 100%, 70%);
  --decoration-bg-color-2: hsl(var(--hue-value-2), 100%, 70%);
}

/*! color 2 */
.color-2 {
  --hue-value-1: 230;
  --hue-value-2: 27;
  --main-color: hsl(var(--hue-value-1), 74%, 61%);
  --button-hover-color: hsl(var(--hue-value-1), 57%, 53%);
  --body-bg-color: hsl(var(--hue-value-1), 63%, 97%);
  --border-color-2: hsl(var(--hue-value-1), 40%, 93%);
  --shadow: 0 0 10px hsl(var(--hue-value-1), 40%, 90%);
  --body-gradient-bg-color-1: hsla(var(--hue-value-1), 100%, 50%, 0.2);
  --body-gradient-bg-color-2: hsla(var(--hue-value-2), 100%, 50%, 0.2);
  --decoration-bg-color-1: hsl(var(--hue-value-1), 100%, 70%);
  --decoration-bg-color-2: hsl(var(--hue-value-2), 100%, 70%);
}
/*! color 3 */
.color-3 {
  --hue-value-1: 36;
  --hue-value-2: 230;
  --main-color: hsl(var(--hue-value-1), 74%, 61%);
  --button-hover-color: hsl(var(--hue-value-1), 57%, 53%);
  --body-bg-color: hsl(var(--hue-value-1), 63%, 97%);
  --border-color-2: hsl(var(--hue-value-1), 40%, 93%);
  --shadow: 0 0 10px hsl(var(--hue-value-1), 40%, 90%);
  --body-gradient-bg-color-1: hsla(var(--hue-value-1), 100%, 50%, 0.2);
  --body-gradient-bg-color-2: hsla(var(--hue-value-2), 100%, 50%, 0.2);
  --decoration-bg-color-1: hsl(var(--hue-value-1), 100%, 70%);
  --decoration-bg-color-2: hsl(var(--hue-value-2), 100%, 70%);
}
/*! color 4 */
.color-4 {
  --hue-value-1: 277;
  --hue-value-2: 36;
  --main-color: hsl(var(--hue-value-1), 74%, 61%);
  --button-hover-color: hsl(var(--hue-value-1), 57%, 53%);
  --body-bg-color: hsl(var(--hue-value-1), 63%, 97%);
  --border-color-2: hsl(var(--hue-value-1), 40%, 93%);
  --shadow: 0 0 10px hsl(var(--hue-value-1), 40%, 90%);
  --body-gradient-bg-color-1: hsla(var(--hue-value-1), 100%, 50%, 0.2);
  --body-gradient-bg-color-2: hsla(var(--hue-value-2), 100%, 50%, 0.2);
  --decoration-bg-color-1: hsl(var(--hue-value-1), 100%, 70%);
  --decoration-bg-color-2: hsl(var(--hue-value-2), 100%, 70%);
}
/*! color 5 */
.color-5 {
  --hue-value-1: 164;
  --hue-value-2: 0;
  --main-color: hsl(var(--hue-value-1), 74%, 61%);
  --button-hover-color: hsl(var(--hue-value-1), 57%, 53%);
  --body-bg-color: hsl(var(--hue-value-1), 63%, 97%);
  --border-color-2: hsl(var(--hue-value-1), 40%, 93%);
  --shadow: 0 0 10px hsl(var(--hue-value-1), 40%, 90%);
  --body-gradient-bg-color-1: hsla(var(--hue-value-1), 100%, 50%, 0.2);
  --body-gradient-bg-color-2: hsla(var(--hue-value-2), 100%, 50%, 0.2);
  --decoration-bg-color-1: hsl(var(--hue-value-1), 100%, 70%);
  --decoration-bg-color-2: hsl(var(--hue-value-2), 100%, 70%);
}
