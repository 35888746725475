.rating-average {
  color: var(--balck-90);
}

.comment-img {
  width: min(50px, 100%);
}
.reviews-comment p {
  text-align: justify;
}

.bars-star {
  min-width: 40px;
}
.bars-pecent {
  min-width: 30px;
  text-align: center;
}
