.header {
  border-bottom: 0.1rem solid var(--border-color-2);
}

.navbar-brand a {
  color: var(--balck-70);
}

.navbar-brand span {
  color: var(--main-color);
}

.navbar-brand a:hover {
  color: var(--balck-70);
}
.navbar-togglre {
  color: var(--black-70);
}
.nav-item {
  position: relative;
}

.header .nav-link:focus {
  color: var(--main-color) !important;
}

.nav-item a {
  color: var(--balck-70);
  display: block;
  padding: 0.8rem 0;
}

.nav-item:hover > a {
  color: var(--main-color);
}

.sub-menu .nav-item {
  display: block;
  width: 100%;
}

.sub-menu {
  box-shadow: var(--shadow);
  background-color: var(--white);
  position: absolute;
  top: 100%;
  left: 0;
  width: 8rem;
  transition: all 0.3s ease;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  z-index: 100;
}

.nav-item:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
@media (max-width: 992px) {
  .navbar-nav-container {
    background-color: var(--white);
    position: fixed;
    top: 0;
    right: -13rem;
    height: 100vh;
    width: 13rem;
    z-index: 100;
    transition: right 0.3s ease-in;
  }
  .navbar-nav-container.active {
    right: 0;
  }
  .nav-item {
    width: 100% !important;
    border-bottom: 0.1rem solid var(--border-color-1);
  }
  .nav-item:first-child {
    border-top: 0.1rem solid var(--border-color-1);
  }
  .header .sub-menu {
    position: relative;
    width: 100%;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: translateY(0);
    top: auto;
    padding: 0;
    border-radius: 0;
    height: 0;
    overflow: hidden;
  }
  .header .sub-menu.active {
    height: 7rem;
  }

  .header .nav-item i {
    transition: 0.3s;
  }
  .rotate {
    transform: rotate(-180deg);
  }
  .sub-menu .nav-item:last-child {
    border-bottom: none;
  }

  .header-close-btn {
    color: var(--WHITE);
    width: 35px;
    height: 35px;
    display: flex;
  }
  .header-close-btn i {
    margin: auto;
  }

  .header-backdrop {
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--white);
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
  }
  .header-backdrop.active {
    visibility: visible;
    opacity: 0.7;
  }
}
